.app-screenshots {
  margin: 80px 0;

  &__title {
    margin-bottom: 8px;
  }

  &__tag-line {
    margin-bottom: 0;
  }

  &__container {
    margin-bottom: 16px;

    @include screen-from(lg) {
      margin-bottom: 60px;
    }
  }

  &__single-app {
    margin: 0 0 60px;

    @include screen-from(md) {
      margin: 8px 0 0;
    }
  }

  &__switch {
    display: block;
    width: fit-content;
    margin-top: 40px;
    margin-bottom: -12px;
    border-bottom: 1px solid $light-silver;

    @include screen-from(sm) {
      margin-bottom: -60px;
    }

    @include screen-from(lg) {
      margin-bottom: -36px;
    }
  }

  &__switch-item {
    display: inline-block;
    padding-bottom: 8px;
    color: $heather;
    cursor: pointer;

    transition: color 0.2s;

    &[data-active] {
      color: initial;
      border-bottom: 1px solid $dark-grey;
    }

    & + & {
      margin-left: 40px;
    }
  }

  &__mobile-carousel {
    .carousel__slides {
      border-radius: 16px;
    }

    .carousel__pagination {
      display: none;
    }
  }

  &__description-container {
    flex-direction: column-reverse;
    align-items: flex-start;
    align-content: flex-start;

    @include screen-from(lg) {
      flex-direction: row;
    }
  }

  &__see-more-container {
    margin-top: 20px;
  }

  &__see-more-button {
    color: $dusty-orange;
    position: relative;
    text-decoration: none;
  }

  &__requirements {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 40px;

    @include screen-from(md) {
      flex-direction: row;
    }

    @include screen-from(lg) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &__requirements-item {
    & + & {
      margin-top: 8px;

      @include screen-from(md) {
        margin-left: 12px;
        margin-top: 0;
      }

      @include screen-from(lg) {
        margin-left: 0;
        margin-top: 8px;
      }
    }
  }

  .carousel {
    overflow: visible;

    @include screen-from(lg) {
      padding: 0;
    }

    &__slides {
      padding-top: 16px;
      padding-bottom: 0;
      height: auto;
    }

    &__pagination {
      @include screen-from(lg) {
        display: none;
      }
    }
  }

  .app-screenshot {
    width: 100%;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    box-sizing: border-box;
    max-width: 580px;

    @include screen-from(md) {
      max-width: 100%;
    }
  }
}
