@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import 'variables';

@function image-url($url) {
  @return $images-path + $url;
}

@mixin screen-from($size) {
  @include media-breakpoint-up($size) {
    @content;
  }
}

@mixin screen-to($size) {
  @include media-breakpoint-down($size) {
    @content;
  }
}

@mixin screen2x() {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin word-break {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

@mixin navTextStyle {
  font-size: $font-size-text-xs;
  font-weight: $font-weight-medium;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: left;
}
