.apps-use-cases {
  position: relative;
  padding: 40px 0 20px;
  margin-top: 40px;

  @include screen-from(md) {
    padding-bottom: 60px;
    margin-top: 60px;
  }

  @include screen-from(lg) {
    margin-top: 80px;
  }

  &__title {
    margin-bottom: 20px;

    @include screen-from(md) {
      margin-bottom: 12px;
    }
  }

  &_single-app {
    margin-bottom: 60px;

    @include screen-from(md) {
      margin-bottom: 72px;
    }

    @include screen-from(xl) {
      margin-bottom: 80px;
    }
  }

  &__description {
    padding-bottom: 44px;
    margin-bottom: 48px;
    border-bottom: 1px solid $heather;

    @include screen-from(md) {
      padding-bottom: 64px;
      margin-bottom: 60px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    color: $white;
    margin: 0 -10px 28px;

    &:last-child {
      margin-bottom: 24px;
    }

    @include screen-from(md) {
      margin-bottom: 60px;
    }

    @include screen-from(lg) {
      flex-direction: row;
      cursor: pointer;
      padding: 20px;
      margin-left: -20px;
      margin-right: -20px;
      border-radius: 15px;
      margin-bottom: 28px;
      transition: background 0.2s, color 0.2s;

      &:hover {
        background: rgba(255 255 255 / 0.15);
      }

      .apps-use-cases__radio:checked + & {
        background: $white;
        color: initial;
      }
    }
  }

  &__item-icon {
    margin-left: -8px;
    margin-bottom: 12px;

    @include screen-from(lg) {
      margin-right: 36px;
      margin-bottom: 0;
    }
  }

  &__item-title {
    color: $white;
    margin-bottom: 8px;

    @include screen-from(lg) {
      transition: color 0.2s;

      .apps-use-cases__radio:checked + .apps-use-cases__item & {
        color: initial;
      }
    }
  }

  &__item-image {
    height: auto;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;

    @include screen-from(lg) {
      cursor: initial;
      position: absolute;
      top: 0;
      left: calc(100% + 60px);
      width: calc(140% - 60px);
      margin: 0;
      visibility: hidden;
      opacity: 0;

      .apps-use-cases__radio:checked + .apps-use-cases__item & {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__footer {
    text-align: center;

    @include screen-from(lg) {
      margin-top: 40px;
    }
  }
}
