.application-setapp-promo {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 172px;

  @include screen-from(sm) {
    flex-flow: row wrap;
    align-items: center;
    padding-bottom: 74px;
  }

  &_single-app {
    padding: 40px 20px 60px;

    @include screen-from(md) {
      padding: 60px 40px 80px;
    }

    @include screen-from(xl) {
      padding: 100px 90px;
    }
  }

  &__details {
    margin-top: 16px;

    @include screen-from(sm) {
      margin: 0 40px;
    }
  }

  &__rating {
    padding-bottom: 12px;
    border-bottom: 1px solid $light-silver;

    @include screen-from(sm) {
      margin-bottom: 16px;
    }
  }

  &__app-requirements {
    position: absolute;
    bottom: 80px;
    display: inline-flex;
    flex-direction: column;
    margin-top: 64px; // safe space for apps without ratings

    @include screen-from(sm) {
      position: initial;
      flex-direction: row;
    }

    &_single-app {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @include screen-from(md) {
        display: none;
      }
    }
  }

  &__rating + &__app-requirements {
    margin-top: 0;
  }

  &__app-requirements-item {
    & + & {
      margin-top: 12px;

      @include screen-from(sm) {
        margin-top: 0;
        margin-left: 12px;
      }
    }
  }

  &__text-content {
    padding-top: 20px;

    @include screen-from(sm) {
      padding-top: 8px;
    }

    &_single-app {
      padding-top: 32px;
    }

    .application-summary__header {
      padding-top: 0;

      @include screen-from(lg) {
        padding-top: 40px;
      }

      &_single-app {
        padding: 0;
        margin-bottom: 40px;
        font-weight: $font-weight-hero-heading;
        border-bottom: none;

        @include screen-from(md) {
          margin-bottom: 28px;
        }

        @include screen-from(xl) {
          margin-bottom: 40px;
        }
      }
    }

    .application-summary__setapp-with-logo {
      padding-left: 46px;

      @include screen-from(lg) {
        padding-left: 72px;
      }

      &::before {
        width: 40px;
        height: 40px;

        @include screen-from(lg) {
          width: 64px;
          height: 64px;
        }
      }
    }
  }

  &__cta {
    margin-top: 40px;

    @include screen-from(sm) {
      margin-top: 24px;
    }
  }

  &__app-icon {
    display: block;
    width: 120px;
    height: 120px;

    @include screen-from(sm) {
      width: 180px;
      height: 180px;
    }
  }
}
