.application-page__app-description {
  &_container {
    flex-direction: column-reverse;
    align-items: flex-start;
    align-content: flex-start;

    @include screen-from(lg) {
      flex-direction: row;
    }
  }

  &_rating {
    @include screen-from(xl) {
      margin-left: 40px;
    }
  }
}
