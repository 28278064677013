.customer-reviews {
  padding: 80px 0 62px;
  border-bottom: 1px solid $light-silver;

  @include screen-from(lg) {
    padding: 80px 0;
  }

  &_single-app {
    border: 1px solid $light-silver;
    border-left: none;
    border-right: none;

    @include screen-from(md) {
      margin-top: 80px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    @include screen-from(md) {
      flex-direction: row;
      align-items: center;
    }

    @include screen-from(lg) {
      margin-bottom: 40px;
    }
  }

  &__icon {
    width: 128px;
    height: 128px;

    @include screen-from(md) {
      width: 154px;
      height: 154px;
    }

    @include screen-from(lg) {
      width: 180px;
      height: 180px;
    }
  }

  &__app-details {
    margin-top: 12px;

    @include screen-from(md) {
      margin-top: 0;
      margin-left: 20px;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    @include screen-from(md) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__divider {
    display: inline-block;
    width: 1px;
    height: 16px;
    background: $dark-grey-blue;
    margin: 0 16px;
  }

  &__card-container {
    & + & {
      margin-top: 16px;

      @include screen-from(md) {
        margin: 0;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
  }

  &__see-more {
    text-decoration: none;
    display: inline-block;
    position: relative;
    padding-left: 44px;
    margin-bottom: 0;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      height: 24px;
      background: url(image-url('/pages/application/reviews/arrow.svg'));
    }
  }
}
