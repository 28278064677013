.platform-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  @include screen-from(xl) {
    flex-direction: row;
    align-items: center;
  }

  &__platform-text {
    font-size: $font-size-text-md;
    font-weight: $font-weight-bold;
    padding-right: 16px;

    @include screen-from(xl) {
      border-right: 1px solid $light-silver;
    }
  }

  &__notarized {
    &-icon {
      display: inline;
      vertical-align: middle;
      margin-bottom: 2px;
    }

    &-text {
      display: inline;
      margin-bottom: 0;
      font-size: $font-size-text-md;
    }
  }
}
