.video-preview {
  &__preview-container_small {
    @include screen-from(lg) {
      padding-top: 46px;
    }
  }

  &_small {
    padding: 60px 10px 20px;

    @include screen-from(lg) {
      padding: 40px 10px;
    }
  }

  &__preview-description {
    font-size: $font-size-h3-mobile;
    line-height: $line-height-small;

    @include screen-from(lg) {
      font-size: $font-size-h3;
    }
  }

  &__preview-name {
    font-size: $font-size-text-lg;
  }

  &__play-btn {
    top: auto;
    left: auto;
    right: 12px;
    bottom: 8px;
    width: 32px;
    height: 32px;

    @include screen-from(sm) {
      right: 24px;
      bottom: 24px;
      width: 72px;
      height: 72px;
    }
  }

  &__image {
    max-height: 136px;
    left: 0;
    bottom: 20px;

    @include screen-from(md) {
      max-height: 300px;
      bottom: 46px;
      left: 16px;
    }

    &-container {
      cursor: pointer;

      @include screen-from(md) {
        max-width: 730px;
        width: auto;
      }

      @include screen-from(xl) {
        min-width: 730px;
      }
    }
  }

  &__image-container:hover &__play-btn {
    transform: scale(1.1);
  }
}
