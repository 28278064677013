.application-summary {
  padding: 40px 0 0;

  @include word-break;

  @include screen-from(sm) {
    padding-top: 48px;
  }

  @include screen-from(lg) {
    padding-top: 60px;
  }

  &__section {
    display: flex;
    flex-direction: column;

    @include screen-from(xl) {
      gap: 40px;
      flex-direction: row;
    }
  }

  &__header {
    line-height: $line-height-small;
    padding-top: 20px;
    padding-bottom: 32px;
    margin-bottom: 31px;
    border-bottom: 1px solid $light-silver;

    @include screen-from(md) {
      padding-bottom: 28px;
      margin-bottom: 27px;
    }

    @include screen-from(lg) {
      padding-bottom: 32px;
      margin-bottom: 31px;
    }

    .recommended-promo-apps__separator {
      margin-top: 32px;
    }
  }

  &__single-app-header {
    padding-top: 28px;
    padding-bottom: 28px;
    border-bottom: 1px solid $light-silver;

    @include screen-from(md) {
      padding-bottom: 32px;
    }

    @include screen-from(xl) {
      padding-top: 12px;
    }
  }

  &__app-name {
    display: inline-block;
    font-size: $font-size-h1-hero-mobile;
    font-weight: $font-weight-hero-heading;
    line-height: 52px;
    margin-bottom: 0;

    &_single-app {
      margin-bottom: 4px;
    }
  }

  &__header-title {
    margin: 4px 0 12px;
  }

  &__setapp-with-logo {
    display: inline-block;
    position: relative;
    padding-left: 36px;

    &::before {
      position: absolute;
      content: '';
      width: 32px;
      height: 32px;
      left: 0;
      bottom: 4px;
      background: url(image-url('/components/logo/setapp-icon.svg')) center no-repeat;
      background-size: contain;
    }
  }

  &__tag-line {
    margin-bottom: 32px;
    font-size: $font-size-text-lg;
    font-weight: $font-weight-regular;
    line-height: 1.4;
    letter-spacing: initial;
  }

  &__cta-block {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__cta-info {
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    @include screen-from(sm) {
      padding-top: 0;
    }
  }

  &__cta-title {
    font-size: $font-size-h6;
    font-weight: $font-weight-heading;
    margin-bottom: 4px;
  }

  &__cta-text {
    font-size: $font-size-text-md;
  }

  &__cta-button-wrapper {
    &--secondary {
      @include screen-from(xl) {
        padding-left: 30px;
      }
    }
  }

  &__cta-button {
    display: block;

    @include screen-to(lg) {
      padding: 12px 4px;
    }

    &--secondary {
      max-width: 75%;
      width: 100%;

      @include screen-to(lg) {
        max-width: 100%;
      }
    }
  }

  &__icon {
    display: block;
    width: 128px;
    height: 128px;
  }

  &__single-app-icon {
    display: block;
    width: 120px;
    height: 120px;

    @include screen-from(xl) {
      width: 160px;
      height: 160px;
    }

    &_rounded {
      padding: 10px;
      border-radius: $rounded-app-icon-radius;
    }
  }

  &__rating {
    display: inline-block;
    margin-top: 8px;
    padding: 8px 16px 9px;
    border-radius: 15px;
    border: solid 1px $pale-grey-light;
    background: $white;
  }

  &__rating-amount {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__info {
    @include screen-to(md) {
      margin-top: 50px;
    }
  }

  &__cta {
    margin-top: 32px;
    margin-bottom: 52px;

    @include screen-from(sm) {
      margin-top: 25px;
    }
  }

  &__screenshots-title {
    margin-bottom: 8px;
  }
}
