.app-in-setapp-illustration {
  position: relative;
  margin-top: 40px;
  padding-top: 47%;
  width: 100%;
  background-color: $blue-grey;
  border: 3px solid $blue-grey;
  border-radius: 15px;
  background-image: url(image-url('/pages/application/setapp-illustration.svg'));
  background-repeat: no-repeat;
  background-position: center 260%;
  background-size: 90%;

  @include screen-from(md) {
    margin-top: 24px;
  }

  @include screen-from(lg) {
    margin-top: 80px;
  }

  @include screen-from(lg) {
    margin: 0;
    width: 868px;
    height: 414px;
    background-position-y: 54px;
    background-size: initial;
  }

  &__app-info {
    position: absolute;
    display: flex;
    top: 39.5%;
    left: 26%;

    @include screen-from(md) {
      top: 38%;
      left: 25.5%;
    }

    @include screen-from(lg) {
      top: 162px;
      left: 217px;
    }
  }

  &__app-info-image {
    width: 9.5vw;
    height: 9.5vw;

    @include screen-from(lg) {
      width: 88px;
      height: 88px;
    }
  }

  &__app-info-name {
    padding: 1vw;
    font-size: 1.8vw;
    font-weight: $font-weight-bold;

    @include screen-from(lg) {
      padding: 8px 14px;
      font-size: $font-size-text-md;
    }
  }

  &__arrow {
    display: none;

    @include screen-from(xl) {
      display: block;
      position: absolute;
      z-index: 2;
      width: calc(100% + 300px); // to align with the main block that has borders
      top: calc(100% - 8px);
      left: -3px;
      stroke: $blue-grey;
      stroke-width: 3px;
      stroke-linecap: round;
    }
  }
}
