.app-features {
  background: $white;
  border-radius: 20px;
  padding: 74px 10px 32px;

  &__separator {
    border-bottom: 1px solid $heather;
    opacity: 0.4;
    margin: 20px 0 40px;
  }

  &__item {
    color: $dark-grey;
    margin-bottom: 40px;

    &-title {
      margin-bottom: 12px;
      color: $dark-grey;
    }
  }

  &__items-wrapper {
    @include screen-from(lg) {
      columns: 2;
      column-gap: 100px;
    }

    .app-features__item {
      @include screen-from(md) {
        break-inside: avoid;
      }
    }
  }

  &__items {
    padding: 0 5px;

    @include screen-from(sm) {
      padding: 0 30px;
    }
  }
}
