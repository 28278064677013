@import '../../base/variables';

.app-rating-stats {
  display: flex;
  align-items: center;

  &__percentage {
    margin: 0 8px 4px;

    &_sm {
      font-size: $font-size-text-md;
      margin-bottom: 0;
    }
  }

  &__amount {
    position: relative;
    padding-left: 12px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 4px;
      height: 4px;
      margin-top: -2px;
      border-radius: 50%;
      background: $dark-grey;
    }
  }
}
