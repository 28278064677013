@import '../../base/mixins';
@import '../../base/variables';
@import '../../components/video-modal/video-modal';
@import '../../components/video-preview/video-preview';
@import '../../components/app-rating-stats/app-rating-stats';
@import 'partials/application-summary';
@import 'partials/customer-reviews';
@import 'partials/app-in-setapp-illustration';
@import 'partials/application-description';
@import 'partials/screenshots-section';
@import 'partials/apps-use-cases';
@import 'partials/application-features';
@import 'partials/how-to';
@import 'partials/setapp-promo';
@import 'partials/platform-info';

.application-page {
  background-color: $white;
  overflow: hidden;

  &__recommended-promo-apps {
    margin-top: 40px;

    @include screen-from(sm) {
      margin-top: 60px;
    }

    @include screen-from(md) {
      margin-top: 64px;
    }

    @include screen-from(lg) {
      padding-bottom: 0;
      margin-top: 42px;
    }
  }

  &__features {
    margin-bottom: 0;
  }

  &__how-to {
    margin-top: 80px;
    padding-bottom: 12px;

    @include screen-from(sm) {
      padding: 10px 0 20px;
    }

    @include screen-from(lg) {
      padding: 0 0 40px;
    }
  }

  &__setapp-promo {
    padding-bottom: 20px;
  }

  &__setapp-promo-bg {
    background-image: linear-gradient(to bottom, $white 0, $white 230px, $footer-bg-color 230px, $footer-bg-color 100%);

    @include screen-from(lg) {
      background-image: linear-gradient(to bottom, $white 0, $white 165px, $footer-bg-color 165px, $footer-bg-color 100%);
    }
  }

  &__rounded-app-icon {
    padding: 10px;
    border-radius: $rounded-app-icon-radius;
  }
}
